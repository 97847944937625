.App {
  text-align: center;
  background-color: #282c34;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  color: white;
}

.time-left {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  line-height: 1.5em;
  color: white;
}

.date-picker {
  margin: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
